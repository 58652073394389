@import "../../styles/base/variables";

.fromBrokerQuoteEngine {
  color: $app-color-red5;
  font-weight: bold;
}

.fromIOLandingPage {
  color: $app-color-blue3;
  font-weight: bold;
}
