@import "../../styles/base/variables";

.user-builder {
  &__wrap {
    width: 100%;
    background-color: $app-color-white;
    box-shadow: $border-shadow;
    padding: 4rem;
    margin-top: 3rem;
  }

  &__button-wrap {
    margin-top: 4rem;
    width: 90%;
    text-align: right;
  }

  &__card-title {
    margin-bottom: 2rem;
    h2 {
      color: $app-color-grey10;
      text-transform: uppercase;
      font-weight: 900;
    }
  }
}
