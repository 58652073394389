.icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  // default size
  svg {
    width: 100%;
    height: 100%;
  }
}
