@import "../../styles/base/mixins";
@import "../../styles/base/variables";

.field-input {
  max-width: 100% !important;
}

.campaignDncPhone-builder {
  &__wrap {
    max-width: 800px;
    width: 100%;
    margin: 0;
    margin-right: 3rem;
  }

  &__container {
    box-shadow: $box-shadow2;
    background-color: $app-color-white;
    margin: 3rem;
    padding: 4rem;
  }

  &__form {
    display: flex;
    flex-direction: row;
  }

  &__top-bar {
    max-width: 74rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-end;
    margin: 1rem;

    .buttons {
      margin-left: auto;
      margin-right: 1rem;
      margin-top: 2rem;

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }
    }
  }

  &__card-wrap {
    min-width: 30rem;
    background-color: $app-color-white;
    min-height: 10rem;
    margin-bottom: 2rem;
    box-shadow: $box-shadow2;
    margin-top: 3rem;

    .card-values {
      color: $app-color-grey2;
    }

    .card-row {
      padding: 1rem;
    }

    .card-separator {
      background-color: $app-color-grey8;
      min-height: 2px;
    }

    .card-totals {
      font-weight: bold;
    }
  }

  &__card-title {
    color: $app-color-white;
    background-color: $app-color-grey2;
    padding: 24px 20px;
  }

  &__radio_wrap {
    position: relative;
    top: -2rem;
    color: $app-color-blue3;
    font-size: 1.2rem;

    label {
      input[type="radio"] {
        font-size: 1.2rem;
        min-width: 1rem;
        min-height: 1rem;
        cursor: pointer;
      }
    }
  }
}

.logoPreview {
  max-height: 100px;
  max-width: 100px;
}

.left {
  text-align: left;
}

#email-body-field {
  border-bottom: 1px solid #edeff2;
  textarea {
    margin-top: 0rem;
    margin-bottom: 2rem;
    width: 100%;
    border: none;
    min-height: 10rem;
    font-size: 1.4rem;

    &:active,
    &:focus {
      outline: none;
    }
  }
}

.buttons {
  margin-top: 1rem;
}

.step {
  margin-bottom: 5rem;
  h1 {
    margin-bottom: 2rem;
  }
  .options {
    min-height: 4rem;
    align-items: center;
  }
}
