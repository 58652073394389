.bootsrap-container-override {
  padding: 0;
  h1.heading3 {
    margin-bottom: 1rem;
  }
}

.bootstrap-col-pad-override {
  .col {
    padding-left: 0;
  }

  .col-sm-4 {
    padding-left: 0;
  }
}
