@import "../../styles/base/variables";

.campaign-stepper {
  padding: 2rem 0;

  &__step {
    &__active {
      color: $app-color-red4;
    }
  }

  .MuiStepper-root {
    padding: 24px;
  }

  svg {
    font-size: 2.4rem;
  }
}
