@import "../../styles/base/variables";

.centered-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.invalid-token {
  margin-bottom: 25px;
  color: $app-color-white;
  font-size: 15px;
}
