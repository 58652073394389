@import "../../styles/base/variables";

.nowrap {
  display: block;
  width: 20rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.broker-docs {
  &__wrap {
    display: flex;
    flex-direction: row;
  }

  &__form-wrap {
    margin-top: 3rem;
    padding: 2rem;

    ul {
      margin-bottom: 5rem;
    }

    h1 {
      margin-bottom: 1rem;
    }

    .field-input {
      width: 100%;
    }
  }

  &__container {
    max-width: 1000px;
    width: 100%;
    margin: 0;
    margin-right: 3rem;
  }

  &__builder {
    box-shadow: $box-shadow2;
    background-color: $app-color-white;
    margin-bottom: 3rem;
  }

  &__aside-wrap {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
  }

  &__card-wrap {
    min-width: 30rem;
    background-color: $app-color-white;
    min-height: 10rem;
    margin-bottom: 2rem;
    color: $app-color-white;
    box-shadow: $box-shadow2;
  }

  &__card-title {
    background-color: $app-color-grey2;
    padding: 24px 20px;
  }
}

.buttons {
  &__wrap {
    text-align: right;
  }

  &__display {
    display: inline-block;
  }

  &__mr-20 {
    margin-right: 2rem;
  }
}

.doc-tab {
  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;

    h1 {
      margin: 0;
    }
  }

  &__title2 {
    margin-top: 7rem;
    margin-bottom: 2rem !important;
  }

  &__table-override {
    .table-container {
      .table-view__wrap {
        box-shadow: none;
        border: 1px solid #edeff2;
      }
    }
  }
}
