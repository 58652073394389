@import "../../../../styles/base/variables";

.trackItemContainer {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  overflow: hidden;
  text-overflow: ellipsis;
  color: $app-color-grey;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.pointer {
  cursor: pointer;
}

.trackItem {
  color: $app-color-grey;
  flex-grow: 1;
}

.trackItemCopy {
  margin-right: 10px;
  flex-shrink: 0;
  color: $app-color-grey;
}
