@import "../../styles/base/variables";

.table-view {
  &__header-buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    margin-bottom: 5rem;
  }

  &__header-container {
    display: inline-flex;
    justify-content: flex-end;
    gap: 11px;
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  &__icon-container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $app-color-red4;
  }

  &__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  &__content {
    max-width: 1280px;
  }

  &__wrap {
    box-shadow: $border-shadow;
  }
}

// bs4 overrides

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

label {
  display: inline-block;
  margin-bottom: 5px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
  & > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}
