.table-search {
  &.disabled {
    opacity: 0.2;
  }
  .input-group {
    input {
      width: 20rem;
      height: 3.5rem;
      background-color: transparent;
    }

    .form-control {
      border-radius: 0;
      border: 1px solid black;
      border-right: none;
      outline: none !important;

      &:focus {
        outline: none;
        border-color: black;
        box-shadow: none;
      }
    }

    .search-btn {
      background-color: rgba(255, 255, 255, 0) !important;
      background-image: url("../../assets/dashboard/search.png");
      background-repeat: no-repeat;
      background-position: center center;
      width: 3rem;
      box-shadow: none !important;
      border: 1px solid black;
      border-left: none;
    }
  }
}
