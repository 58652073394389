@import "../../styles/base/variables";

.side-nav {
  width: 290px;
  min-height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 0;
  box-shadow: 2px 14px 20px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $app-color-grey;
    min-height: 100vh;
    width: 100%;
    z-index: -1;
  }

  &__logo {
    background-image: url("../../assets/dashboard/DIS-Logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 249px;
    height: 130px;
    margin-top: 20px;
    flex-shrink: 0;
  }

  &__buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__add-button {
    background-color: $app-color-red4;
    color: $app-color-white;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

    display: flex;
    height: 50px;
    padding: 8px 20px 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;

    border-radius: 40px;
    border: 0;
    transition: 300ms box-shadow ease;
    outline: 0;

    &:hover {
      box-shadow:
        0 8px 8px 0 rgba(0, 0, 0, 0.24),
        0 0 8px 0 rgba(0, 0, 0, 0.12);
      outline: 0;
    }

    &:focus,
    &:active {
      outline: 0;
    }
  }

  &__links {
    display: flex;
    width: 249px;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
    flex: 1;

    a {
      font-size: 16px;
      color: $app-color-white;
      text-decoration: none;

      display: flex;
      height: 64px;
      padding: 12px 24px;
      border-radius: 40px;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      transition: 0.3ms;

      &:hover,
      &.isActive {
        color: $app-color-white;
        text-decoration: none;
        background-color: $app-color-grey2;
      }

      &.isActive {
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        background-color: $app-color-red4;
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }
  }

  &__footer {
    width: 100%;
    height: 41px;
    background-color: rgba($color: $app-color-black, $alpha: 0.1);
    color: $app-color-white;
    align-content: center;

    button {
      background-color: transparent;
      border: none;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }

    .dropdown-toggle {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      justify-self: center;

      &::after {
        font-size: 16px;
      }
    }

    .user-email {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    .dropdown-menu {
      &.show {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        left: 6rem !important;

        background-color: #545b62;

        button {
          color: $app-color-white !important;
          text-align: center;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
  &__version {
    cursor: pointer;
    font-size: 10px;
    text-align: center;
    color: #fff;
    opacity: 0.7;
  }

  &__badge {
    background-color: $app-color-red4;
    color: $app-color-white;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
    min-width: 20px;
    text-align: center;
    line-height: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
}
