.manage-product-items-modal {
  &__item-row {
    display: flex;
    align-items: center;
    width: 100%;

    ul {
      margin: 0;
    }
  }

  &__input {
    flex-grow: 1;
  }

  &__delete-button {
    margin-left: 10px;
  }

  &__add-button {
    margin: 15px 0px 15px 5px;
  }
}
