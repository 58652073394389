.hasError {
  outline: 2px solid red;
}

.empty-state {
  text-align: center;
  p {
    font-size: 2rem;
    color: #aaa;
    margin: 2rem 0;
  }
}

.intervalDays {
  width: 3rem;
  text-align: center;
  margin: 0.5rem;
}

.editor-dropdown {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 5px;

  label {
    margin-bottom: 0;
  }

  .dropdown {
    button {
      font-size: 16px;
      &.dropdown-toggle-btn {
        font-size: 14px;
        padding: 0 4px !important;
        background-color: #d85d0f;
        color: #fff;
      }
    }
  }
}

.step-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
}

.step {
  position: relative;
}

.non-editable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  font-size: 14pt;
  font-weight: bold;
  text-align: center;
  padding-top: 3rem;
}
