@import "./variables";

.icon {
    &--client {
        width: 23px;
        height: 23px;
        display: inline-block;
        background-image: url("../../assets/dashboard/multi-life-icon.svg");
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 0.5rem;
    }
}