/**
 *  TableBody.scss
 *  Disability Insurance Services
 *
 *  Created by Jeremy Moyers on Thu Apr  15 11:02:42 2019
 *  Copyright Seamgen, LLC. All rights reserved.
 */
@import "../../styles/base/variables";

.table-container {
  width: 100%;
  // overflow: hidden; // uncomment if table should scroll for tablet/mobile
  // margin-bottom: 0 !important;

  table {
    thead {
      th {
        background-color: rgba(136, 147, 164, 0.15);
      }
      font-size: 11px;
      color: $app-color-black;
      font-weight: 500;

      tr {
        th {
          vertical-align: middle;
          word-break: break-word;
          padding: 2rem 0;

          &:first-child {
            padding-left: 2rem;
          }

          &:last-child {
            padding-right: 2rem;
          }
        }
      }
    }

    tbody {
      background-color: $app-color-white;

      tr {
        td {
          font-size: 11px;
          word-break: break-word;
          color: $app-color-grey3;
          vertical-align: middle;
          padding: 1.5rem 0;

          background-color: transparent;

          &:first-child {
            padding-left: 2rem;
          }

          &:last-child {
            padding-right: 2rem;
          }
        }

        &.has-action:hover {
          background-color: $app-color-grey8;
          cursor: pointer;
        }
      }
    }
  }

  .table-toolbar {
    margin-top: 0rem;
    margin-bottom: 3rem;

    &__wrap {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  .table-footer {
    font-size: 11px;

    &__container {
      border-top: solid 1px #e6e6e6;
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      ul {
        list-style: none;
        margin: 0;

        li {
          display: inline-block;
        }
      }
    }

    &__rows {
      display: flex;

      li {
        &:nth-child(1) {
          margin-right: 1.5rem;
        }

        &:nth-child(2) {
          width: 50px;
          text-align: center;
        }
      }
    }

    &__pagination-page {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      > li {
        text-align: center;

        &:nth-child(1) {
          // left arrow
          margin-right: 0.5rem;
        }

        &:nth-child(2) {
          // label
          width: 7rem;
        }

        &:nth-child(3) {
          // right arrow
          margin-left: 0.5rem;
        }
      }
    }

    &__pagination-select {
      outline: none;
      border: none;
    }
  }

  .table-view {
    &__main-wrap {
      // overflow-x: auto; // uncomment if table should scroll for tablet/mobile

      &__inner {
        // min-width: 109rem; // uncomment if table should scroll for tablet/mobile
      }
    }
  }
}
