@import "../../../../../styles/base/variables";

.proposal-details {
  &__wrap {
    display: flex;
    flex-direction: row;
  }

  &__container {
    max-width: 800px;
    width: 100%;
    margin: 0;
    margin-right: 3rem;
  }

  &__builder {
    box-shadow: $box-shadow2;
    background-color: $app-color-white;
    margin-bottom: 3rem;
    .caseStatus {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      align-items: center;
      .item {
        label {
          font-weight: bold;
          margin: 0;
        }
        .value {
          font-size: 1.5rem;
        }
        align-items: center;
        display: flex;
        gap: 5px;
      }
    }
  }

  &__head-id {
    min-height: 6.5rem;
    background-color: rgba(136, 147, 164, 0.18);
    border-top: 0.1rem solid rgba(136, 147, 164, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 4rem;

    .single-select-field {
      ul {
        margin-bottom: 1.3rem;
      }
    }
  }
}

.buttons {
  &__wrap {
    text-align: right;
    padding-bottom: 2rem;
  }

  &__display {
    display: inline-block;
  }

  &__mr-20 {
    margin-right: 2rem;
  }
}
