@import "../../../styles/base/variables";

.dropzone {
  background-color: #edeff2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 10rem;
  font-size: 1.2rem;
  cursor: pointer;

  &__error {
    color: #c2112e;
  }
}
