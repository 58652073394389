.input-editor {
  overflow: hidden;
  margin-bottom: 24px;
  .ql-container {
    border-bottom: solid 1px #000000 !important;

    .ql-editor {
      padding: 0;
      min-height: 30px;
      max-height: 30px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
