@import "../../../styles/base/variables";

.dob {
  color: #000;
  width: 100%;

  &__wrap {
    & fieldset {
      display: none;
    }
    ul {
      margin-bottom: 24px;
      padding: 0;
      li {
        list-style: none;
        &:nth-child(2) {
          border-bottom: 1px solid black;
          .field-input {
            position: relative;
            &:focus {
              background-color: $app-color-grey-5;
            }
          }
        }
      }
    }
    label.MuiInputLabel-formControl {
      transform: translate(0, 1.5px) scale(1);
      font-size: 1.4rem;
    }

    .MuiInputLabel-asterisk {
      color: $app-color-red;
    }
    input {
      font-size: 1.8rem;
      padding: 0;
      border: none;
      outline: none;
    }

    &__error {
      color: $app-color-red;

      label {
        color: $app-color-red;
      }
      input {
        color: $app-color-red !important;
      }
    }
  }
}
