@import "../styles/base/variables";

.app-container {
  display: flex;
  flex-direction: row;
}

.main-container {
  margin-left: 290px;
  flex: 1;
  min-height: 100vh;
  padding: 2rem;
  background-color: $app-color-grey-5;
}
