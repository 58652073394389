@import "../../styles/base/variables";

.main {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  &__wrap {
    width: 100%;
    margin: 0;
  }
  & aside {
    a {
      text-decoration: none;
    }
  }

  .aside-nav-bar {
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }
}

.campaigns__options-drawer {
  &__list-item-text > span {
    font-size: 16px;
  }

  hr {
    background-color: $app-color-black;
  }
}

.status-badge {
  padding: 8px 10px 8px 30px !important;
  position: relative;
  border-radius: 12px !important;
  width: 11rem;
  max-width: 11rem;
  text-align: center !important;
  font-size: 1.1rem !important;
  height: 2.5rem;

  &--scheduled {
    background-color: $app-color-red2 !important;
  }

  &--completed {
    background-color: $app-color-green !important;
  }

  &--inprogress {
    background-color: $app-color-yellow !important;
  }

  &--draft {
    text-align: center !important;
    background-color: #888 !important;
  }
}
