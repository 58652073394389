@import "../../styles/base/variables";
@import "../../styles/base/mixins";

.campaign-details {
  &__wrap {
    display: flex;
    flex-direction: row;
  }

  &__container {
    width: 100%;
    margin: 0;
  }

  &__builder {
    margin-bottom: 3rem;
  }

  &__builder__form {
    padding: 2rem;
  }

  .campaign-filename {
    display: flex;
    gap: 1rem;
    align-items: center;
    div:nth-child(2) {
      flex: 1;
    }
  }

  .preview-container {
    .outreach-heading-container {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      margin-bottom: 1.5rem;
    }

    .preview-box {
      .row {
        margin-bottom: 1rem;
      }
    }

    .preview-box-email-subject {
      margin-bottom: 2rem;
    }

    .editor-padding-0 {
      .ql-editor {
        padding: 0 !important;
      }
    }
  }

  .row {
    font-size: 16px;
  }
}
.sequence-error {
  color: $app-color-red;
  margin-top: 1rem;
}

.iobuttons {
  &__wrap {
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__left {
    display: flex;
    gap: 1rem;
  }

  &__right {
    display: flex;
    gap: 1rem;
  }
}

.preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  h1 {
    margin-bottom: 0;
  }

  .preview-header__title {
    font-weight: 500;
  }

  .preview-header__actions {
    display: flex;
    gap: 1rem;
  }
}
