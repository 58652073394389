@import "../../../../styles/base/variables";

.blue {
  background-color: $app-color-blue;
}

.red {
  background-color: $app-color-red;
  color: white;
}

.qaTrackItem {
  margin: 4px 10px 4px 10px;
  color: $app-color-grey;
}
