@import "../../../styles/base/variables";

.checkbox-field {
  font-size: 1.1rem;
  position: relative;
  z-index: 0;

  label {
    padding-left: 3rem;
    position: relative;
    z-index: 1;
    &:hover {
      cursor: pointer;
    }
  }

  &:before,
  &:after {
    display: inline-block;
    content: "";
    position: absolute;
    transition: 300ms ease all;
    z-index: -1;
  }

  &:before {
    width: 1.8rem;
    height: 1.8rem;
    border: 2px solid $app-color-grey;
    top: -0.2rem;
    left: 0;
  }

  &:after {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 1.7rem; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: "liga";

    width: 1.8rem;
    height: 1.8rem;

    left: 0;
    top: -2px;
    color: $app-color-white;
  }
  &.active {
    &:after {
      content: "\e876";
    }

    &:before {
      background-color: $app-color-red4;
      border-color: transparent;
    }
  }

  &.info {
    display: flex;
    align-content: center;
  }

  input {
    visibility: hidden;
    position: absolute;
  }
}
