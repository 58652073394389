@import "../../styles/base/variables";

.stepper {
  margin-top: 4rem;
  .MuiStepper-root &__controls {
    width: 90%;
    margin: 0 auto;
    text-align: right;
  }

  &__step {
    &__active {
      color: $app-color-red4;
    }
  }

  .MuiStepper-root {
    padding: 24px;
  }
}
