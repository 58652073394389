@import "../../styles/base/variables";

.new-indicator {
  color: $app-color-white;
  background-color: $app-color-red4;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
}
