@import "../../../styles/base/variables";

.timePicker {
  color: #000;

  &__wrap {
    & fieldset {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid black;
    }
    ul {
      margin-bottom: 24px;
      padding: 0;
      li {
        list-style: none;
      }
    }
    .MuiInputLabel-asterisk {
      color: $app-color-red;
    }
    input {
      font-size: 1.8rem;
      padding: 0;
      border: none;
      outline: none;
    }
    &__error {
      color: $app-color-red;
      label {
        color: $app-color-red;
      }
      input {
        color: $app-color-red !important;
      }
    }
  }
}
