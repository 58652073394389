@import "../../styles/base/variables";
@import "../../styles/base/mixins";

.main {
  margin-top: 4rem;

  .row {
    font-size: 16px;
  }

  .preview-box {
    border: 1px solid $app-color-grey7;
    background-color: $app-color-grey-5;
    font-size: 1.2rem;
    .row {
      margin-bottom: 1rem;
    }
  }

  .preview-container {
    // to add margin-top to all children except the first one
    & > * + * {
      margin-top: 1.25rem;
    }
  }
}

.campaign-details {
  &__header {
    margin-bottom: 1.5rem;
  }

  .box {
    border: 1px solid $app-color-grey7;
    padding: 1rem;
    margin-bottom: 2rem;
    background: white;
    box-shadow: $border-shadow;
  }

  .ql-editor {
    padding-left: 0;
    padding-right: 0;
  }
}

.summary-list,
.campaign-detail {
  font-size: 12pt;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    gap: 5px;
  }
}
